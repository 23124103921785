import * as React from "react";
import { PageProps, useStaticQuery, graphql } from "gatsby";
import Navbar from "../components/navbar";
import styled from "@emotion/styled";
import * as api from "../api";
import { cleanPhone } from "../utils";
import { TrackConversion, GtagInit } from "../components/conversion";

//
// LOGIC
//

interface Errors {
  firstname?: "is-invalid";
  lastname?: "is-invalid";
  email?: "is-invalid";
  phone?: "is-invalid";
  message?: "is-invalid";
}

const initialState = {
  status: "form" as "form" | "loading" | "done",
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  message: "",
  errors: {} as Errors,
};

type Action =
  | { type: "FORM" }
  | { type: "LOADING" }
  | { type: "DONE" }
  | { type: "ON_CHANGE"; id: string; value: string; checked: boolean }
  | { type: "SET_ERRORS"; errors: Errors };

type State = typeof initialState;

const useContact = () => {
  const [state, dispatch] = React.useReducer((state: State, action: Action) => {
    switch (action.type) {
      case "FORM":
        return { ...state, status: "form" };
      case "LOADING":
        return { ...state, status: "loading" };
      case "DONE":
        return { ...state, status: "done" };
      case "SET_ERRORS":
        return { ...state, errors: action.errors };
      case "ON_CHANGE":
        const { id, value } = action;
        switch (id) {
          case "firstname":
          case "lastname":
          case "message":
            return {
              ...state,
              [id]: value,
              errors: { ...state.errors, [id]: undefined },
            };
          case "email":
            return {
              ...state,
              email: value.trim().toLowerCase(),
              errors: { ...state.errors, [id]: undefined },
            };
          case "phone":
            return {
              ...state,
              phone: cleanPhone(value),
              errors: { ...state.errors, [id]: undefined },
            };
          default:
            return state;
        }
    }
  }, initialState);
  const validate = (state: State) => {
    const errors = [
      state.firstname.trim().length <= 2 ? "firstname" : null,
      state.lastname.trim().length <= 2 ? "lastname" : null,
      state.phone.replace(/ /g, "").length != 10 ? "phone" : null,
    ].filter(key => key !== null);
    return errors.length == 0 ? null : errors;
  };
  const change = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const id = e.target.id;
    dispatch({
      type: "ON_CHANGE",
      id,
      value: e.target.value,
      checked: (e.target as any).checked,
    });
  };
  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    if (state.status == "loading") {
      return;
    }
    const errors = validate(state);
    if (errors) {
      return dispatch({
        type: "SET_ERRORS",
        errors: errors.reduce(
          (acc, key) => ({ ...acc, [key]: "is-invalid" }),
          {}
        ),
      });
    }
    dispatch({ type: "LOADING" });
    api
      .post("/contact", {
        email: state.email,
        firstname: state.firstname,
        lastname: state.lastname,
        phone: state.phone,
        message: state.message,
      })
      .then(() => dispatch({ type: "DONE" }))
      .catch(e => {
        console.log(e);
        dispatch({ type: "FORM" });
      });
  };
  return { state, dispatch, change, submit };
};

//
// STYLES
//

const FormContainer = styled.div`
  background-image: url("${(props: any) => props.image}");
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - 70px);
  .form-box {
    background-color: rgba(255, 255, 255, 0.85);
    max-width: 960px;
  }
  .form-group {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const Form = ({ children }) => {
  const data = useStaticQuery(graphql`
    query ContactFormImage {
      image: file(relativePath: { eq: "bg-chantier.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, maxHeight: 1024) {
            src
          }
        }
      }
    }
  `);
  return (
    <FormContainer
      className="container-fluid pt-5"
      image={data.image.childImageSharp.fluid.src}
    >
      <div className="container mt-5">
        <div className="form-box p-4 mx-auto">{children}</div>
      </div>
    </FormContainer>
  );
};

const VerticalLine = styled.div`
  border-right: 1px solid #333;
  width: 1px;
  height: 80%;
  position: absolute;
  right: 0;
  top: 10%;
  @media (max-width: 767px) {
    display: none;
  }
`;

const ContactInfos = styled.div`
  position: relative;
  p {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 22px;
    font-weight: bold;
  }
  a {
    font-size: 18px;
    color: black;
  }
`;

const Contact = (props: PageProps) => {
  const { state, change, submit } = useContact();
  const disabled = state.status != "form";
  return (
    <>
      <Navbar />
      <Form>
        <h1
          className="h3 text-danger text-center pt-3"
          style={{ textTransform: "uppercase" }}
        >
          Envoyez-nous un message&nbsp;!
        </h1>
        {["form", "loading"].includes(state.status) ? (
          <form onSubmit={submit} className="p-4">
            <div className="row">
              <div className="col-md-5">
                <div
                  className="d-md-flex flex-column justify-content-around"
                  style={{
                    minHeight: "65%",
                  }}
                >
                  <ContactInfos>
                    <VerticalLine />
                    {/*
                    <div className="mt-3">
                      <p>Email</p>
                      <div>
                        <a href="mailto:contact@votre-assurance-decennale.fr">
                          contact@votre-assurance-decennale.fr
                        </a>
                      </div>
                    </div>
                    */}
                  </ContactInfos>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className={"form-control " + state.errors.firstname}
                        type="text"
                        placeholder="Prénom"
                        id="firstname"
                        value={state.firstname}
                        onChange={change}
                        disabled={disabled}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className={"form-control " + state.errors.lastname}
                        type="text"
                        placeholder="Nom"
                        id="lastname"
                        value={state.lastname}
                        onChange={change}
                        disabled={disabled}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className={"form-control " + state.errors.email}
                    placeholder="Adresse email"
                    id="email"
                    value={state.email}
                    onChange={change}
                    disabled={disabled}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="phone"
                    className={"form-control " + state.errors.phone}
                    placeholder="Téléphone"
                    id="phone"
                    value={state.phone}
                    onChange={change}
                    disabled={disabled}
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className={"form-control " + state.errors.message}
                    placeholder="Message"
                    id="message"
                    value={state.message}
                    onChange={change}
                    disabled={disabled}
                    rows={6}
                    required
                  />
                </div>
                <div className="text-center mt-3">
                  <button
                    className="btn-action py-2 px-5"
                    type="submit"
                    disabled={disabled}
                  >
                    Envoyer
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <div className="p-4">
            <p className="lead text-center font-weight-bold">
              Merci de votre confiance, <br />
              votre demande a bien été enregistrée, <br />
              un de nos conseillers vous contactera très prochainement.
            </p>
            <TrackConversion />
          </div>
        )}
      </Form>
      <GtagInit />
    </>
  );
};

export default Contact;
